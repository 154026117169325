import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<inlineCode parentName="p">{`Lib`}</inlineCode>{` module contains external packages that are used frequently in animations.`}</p>
    <h2 {...{
      "id": "import"
    }}>{`Import`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Lib } from "react-ensemble";
`}</code></pre>
    <h2 {...{
      "id": "interface"
    }}>{`Interface`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const Lib = {
  d3Ease,
  d3Interpolate
};
`}</code></pre>
    <h2 {...{
      "id": "d3ease"
    }}>{`d3Ease`}</h2>
    <p>{`References the `}<strong parentName="p">{`d3-ease`}</strong>{` library of easing functions.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/d3/d3-ease/blob/master/README.md"
      }}>{`See the `}<strong parentName="a">{`d3-ease`}</strong>{` documentation.`}</a></p>
    <h2 {...{
      "id": "d3interpolate"
    }}>{`d3Interpolate`}</h2>
    <p>{`References the `}<strong parentName="p">{`d3-interpolate`}</strong>{` library of interpolation functions.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/d3/d3-interpolate/blob/master/README.md"
      }}>{`See the `}<strong parentName="a">{`d3-interpolate`}</strong>{` documentation.`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      